<template>
<div class="loading-data p-grid p-formgrid p-mt-5">
    <div class="p-field p-col-6">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
    <div class="p-field p-col-6">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
</div>
<div class="p-grid p-mt-3">
    <div class="p-col-4">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
    <div class="p-col-4">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
    <div class="p-col-4">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
</div>
<div class="p-grid">
    <div class="p-col-4">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
    <div class="p-col-4">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
</div>
<div class="p-grid p-mt-3">
    <div class="p-col-4">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
    <div class="p-col-4">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
    <div class="p-col-4">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
</div>
<div class="p-grid">
    <div class="p-col-4">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
    <div class="p-col-4">
        <Skeleton height="2rem" class="p-mb-3"></Skeleton>
    </div>
</div>
</template>

<script>
import Skeleton from 'primevue/skeleton';

export default {
    setup() {
        
    },
    components:{
        Skeleton,
    }
}
</script>

<style lang="scss" scoped>
.loading-data {
    width: 800px;
}
</style>