<template>            
<DataTable
    :value="bookingCompanyDataTable"
    responsiveLayout="scroll"
    scrollHeight="245px"
    selectionMode="single"
    v-model:selection="selectedBookingCompanyRow"
    @rowSelect="onBookingCompanyRowSelect"
    dataKey="id"
    :loading="loadingBookingCompanyList"
>
    <Column field="id" header="ID" :style="{'width':'80px', 'min-width':'30px'}"></Column>
    <Column field="company_name" header="Company name" :style="{'min-width':'60px'}"></Column>

    <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
        <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editBookingCompany(slotProps.data)"/>
            <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteBookingCompany(slotProps.data)" />
        </template>
    </Column>
</DataTable>

<div class="bookingcompany-input-bar">
    <form @submit.prevent="bookingCompanyFormType == 'add' ? addBookingCompany() : updateBookingCompany()">
        <div class="p-grid">
            <div class="p-col">
                <InputText v-model="bookingCompany_form.company_name" id="company_name" type="text" placeholder="Company name" :class="{'p-invalid':v_bookingcompany$.company_name.$error}" />
            </div>
            <div class="p-col-fixed" style="text-align: right">
                <Button v-if="bookingCompanyFormType == 'add'" label="Add" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
                <Button v-if="bookingCompanyFormType == 'update'" label="Update" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
            </div>
        </div>
    </form>
</div>
<InlineMessage v-if="requestError.isError" severity="warn">{{ requestError.warnMessage }}</InlineMessage>
</template>

<script>
import { ref, reactive, computed, onMounted} from 'vue';
import { useConfirm } from "primevue/useconfirm";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import BookingService from '../service/BookingService'

export default {
    props: {},
    emits: ['RefreshBookingCompany'],
    setup(props, { emit }) {
        const confirm = useConfirm()

        //On Create
        const bookingService = ref(new BookingService())
        
        onMounted(() => {
            bookingCompanyFormType.value = 'add'
            refreshBookingCompanyList()
        })

        let requestError = reactive({
            isError: false,
            warnMessage: ''
        })
        let bookingCompanyFormType = ref('')
        let bookingCompanyDataTable = ref([])
        let selectedBookingCompanyRow = ref()
        let loadingBookingCompanyList = ref(false)
        let bookingCompany_form = reactive({})
        let bookingCompanyID = ref(0)
        let old_companyname = ''

        const onBookingCompanyRowSelect = () => {
            bookingCompanyFormType.value = 'add'
            initBookingCompanyFormData()
            v_bookingcompany$.value.$reset()
        }

        const initBookingCompanyFormData = () => {
            const initForm = {
                company_name: '',
            }

            Object.assign(bookingCompany_form, initForm)
        }

        const refreshBookingCompanyList = () =>{
            initBookingCompanyFormData()
            loadingBookingCompanyList.value = true

            bookingService.value.getBookingCompany().then((data) => {
                bookingCompanyDataTable.value = data
                
                loadingBookingCompanyList.value = false
            })
        }

        const addBookingCompany = () => {
            if(validateBookingCompanyForm()) {
                bookingService.value.addBookingCompany(bookingCompany_form).then((data) =>{
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            showRequestError(true, data.message)
                        } else {
                            refreshBookingCompanyList()
                            emit('RefreshBookingCompany')
                            v_bookingcompany$.value.$reset()
                            showRequestError(false, '')
                        }
                    } else {
                        showRequestError(true, data.errorResponse)
                    }
                })
            }
        }

        const editBookingCompany = (bookingCompanyData) => {
            bookingCompanyFormType.value = 'update'
            selectedBookingCompanyRow.value = bookingCompanyData
            bookingCompanyID.value = bookingCompanyData.id

            old_companyname = bookingCompanyData.company_name
            bookingCompany_form.company_name = bookingCompanyData.company_name
        }

        const updateBookingCompany = () => {
            if(validateBookingCompanyForm()) {
                if(old_companyname != bookingCompany_form.company_name)  {
                    bookingService.value.updateBookingCompany(bookingCompanyID.value, bookingCompany_form).then((data) =>{
                        if(!data.errorResponse) {
                            if(data.status == 400) {
                                showRequestError(true, data.message)
                            } else {
                                refreshBookingCompanyList()
                                bookingCompanyFormType.value = 'add'
                                emit('RefreshBookingCompany')
                                v_bookingcompany$.value.$reset() //Reset validations
                                showRequestError(false, '')
                            }
                        } else {
                            showRequestError(true, data.errorResponse)
                        }
                    })
                }
            }
        }

        const confirmDeleteBookingCompany = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.company_name +'" company?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const bookingcompany_id = data.id
                    bookingService.value.deleteBookingCompany(bookingcompany_id).then((data) => {
                        if(!data.errorResponse){
                            refreshBookingCompanyList()
                            emit('RefreshBookingCompany')
                        } else {
                            showRequestError(true, data.errorResponse)
                        }
                    })
                }
            });
        }

        const showRequestError = (isError, warnMessage) => {
            requestError.isError = isError
            requestError.warnMessage = warnMessage

            setTimeout(() => {
                requestError.isError = false
                requestError.warnMessage = ''
            }, 4000);
        }

        //Form Validations
        let bookingCompanyRules = computed(() =>  {
            return {
                company_name: { required }
            }
        })
        const v_bookingcompany$ = useVuelidate(bookingCompanyRules, bookingCompany_form)
        const validateBookingCompanyForm = () => {
            v_bookingcompany$.value.$validate();
            if(!v_bookingcompany$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            // showBookingCompanyDialog,
            // isBookingCompanyDialogShow,
            requestError,
            bookingCompanyFormType,
            bookingCompanyDataTable,
            selectedBookingCompanyRow,
            loadingBookingCompanyList,
            onBookingCompanyRowSelect,
            bookingCompany_form,
            v_bookingcompany$,
            addBookingCompany,
            editBookingCompany,
            updateBookingCompany,
            confirmDeleteBookingCompany,
        }
    },
}
</script>

<style lang="scss" scoped>
.bookingcompany-input-bar {
    margin-top: 1.2rem;
}
.bookingcompany-input-bar .p-inputtext{
    width: 100%;
}

.small-datatable-button {
    width: 28px;
}
.small-datatable-button.p-button-icon-only.p-button-rounded {
    height: 28px;
}
</style>