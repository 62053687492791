<template>
<LoadingBookingData v-if="showLoadingScreen" />
<form v-if="!showLoadingScreen" @submit.prevent="bookingInfo.id == '' ? addBooking() : updateBooking()">
    <div class="form-view p-fluid">
        <Divider align="left">
            <div class="p-d-inline-flex p-ai-center" style="background-color: none">
                <span style="font-weight: 500">Booking Information</span>
            </div>
        </Divider>
        <div class="p-grid">
            <div class="p-col-6">
                <label for="tour_code">Tour code:</label>
                <InputText id="tour_code" v-model="bookingInfo.tour_code" type="text" ref="tour_code_input" :class="{'p-invalid':v_bookinginfo$.tour_code.$error}" autofocus />
            </div>
            <div class="p-col-6">
                <label for="no_pax">No. of pax:</label>
                <InputNumber v-model="bookingInfo.pax" :min="0" inputStyle="width: 8rem" :class="{'p-invalid':v_bookinginfo$.pax.$error}" />
            </div>
            <div class="p-col-6">
                <label for="nationality">Nationality:</label>
                <InputText v-model="bookingInfo.nationality" id="nationality" type="text" :class="{'p-invalid':v_bookinginfo$.nationality.$error}" />
            </div>
            <div class="p-col-6">
                <label for="pax">Company:</label>
                <div class="p-grid">
                    <div class="p-col" style="min-width: 105px">
                        <Dropdown v-model="selectedBookingCompany" @change="onBookingCompanyChange()" :options="bookingCompanyList" optionLabel="company_name" placeholder="Select company" :class="{'p-invalid':v_bookinginfo$.bookingcompany_id.$error}" />
                    </div>
                    <div class="p-col-fixed">
                        <Button icon="pi pi-bars" @click="showBookingCompanyDialog" class="pi-plus-resized p-button-secondary" style="width: 25px; height: 25px; margin-top: 2px" /> 
                    </div>

                    <Dialog header="Booking Company" v-model:visible="isBookingCompanyDialogShow" :style="{width: '29rem'}" :modal="true">
                        <BookingCompany @RefreshBookingCompany="getBookingCompany()" />
                    </Dialog>
                </div>
            </div>
            <div class="p-col-6">                
                <label for="booking_type" class="p-mr-3" style="margin-left: 0">Booking type:</label>
                <Dropdown v-model="selectedBookingType" @change="onBookingTypeChange()" :options="bookingType" optionLabel="booking_type" placeholder="Select booking type" :class="{'p-invalid':v_bookinginfo$.booking_type.$error}" />
            </div>
            <div class="p-col-6">
                <label for="pax">Itinerary:</label>
                <Dropdown v-model="selectedItinerary" :options="itineraryList" optionLabel="itinerary_name" @change="getItinLandingArea()" placeholder="Select itinerary" />
            </div>
            <div class="p-col-6">
                <label for="booking_status" class="p-col-fixed">Booking status:</label>
                <div class="p-formgroup-inline">
                    <div class="p-col p-field-radiobutton">
                        <RadioButton id="confirmed" class="booking-confirmed" v-model="bookingStatus" @change="onBookingStatusChange()" name="city" value="confirmed" :class="{'p-invalid':v_bookinginfo$.booking_status.$error}" />
                        <label for="confirmed" class="p-mr-5">Confirmed</label>
                    </div>
                    <div class="p-col p-field-radiobutton">
                        <RadioButton id="cancelled" class="booking-cancelled" v-model="bookingStatus" @change="onBookingStatusChange()" name="city" value="cancelled" :class="{'p-invalid':v_bookinginfo$.booking_status.$error}" />
                        <label for="cancelled" class="p-mr-5">Cancelled</label>
                    </div>
                    <div class="p-col p-field-radiobutton">
                        <RadioButton id="tba" class="booking-tba" v-model="bookingStatus" name="city" @change="onBookingStatusChange()" value="tba" :class="{'p-invalid':v_bookinginfo$.booking_status.$error}" />
                        <label for="tba">TBA</label>
                    </div>
                </div>
            </div>
            <div v-if="can('create', 'booking') || can('update', 'booking')" class="p-col-6" style="text-align: right; align-self: flex-end">                
                <Button :label="bookingInfo.id == '' ? 'Save Booking':'Update Booking'" type="submit" class="p-button-success p-button-sm" style="width: auto"></Button>
            </div>
        </div>

        <Divider align="left">
            <div class="p-d-inline-flex p-ai-center" style="background-color: none">
                <span style="font-weight: 500">Arrival Information</span>
            </div>
        </Divider>
        <div class="p-grid">
            <div class="p-col-4">
                <label for="arrival_date">Arrival date:</label>
                <Calendar id="arrival_date" v-model="arrivalInfo.arrival_date" @date-select="listLandingArea()" :monthNavigator="true" :yearNavigator="true" yearRange="2010:2040" dateFormat="dd-M-yy" style="height: 2.15rem;" :class="{'p-invalid':v_arrivalinfo$.arrival_date.$error}">
                </Calendar>
            </div>
            <div class="p-col-4">
                <label for="arrival_by">Arrival by:</label>
                <Dropdown v-model="selectedArrivalBy" @change="onArrivalByChange()" :options="arr_depart_by" optionLabel="by" :class="{'p-invalid':v_arrivalinfo$.arrival_by.$error}" />
            </div>
            <div class="p-col-4">
                <label v-if="arrivalInfo.arrival_by == '' || arrivalInfo.arrival_by == 'By Flight'" for="flight_vehicle_no">Flight No.:</label>
                <label v-if="arrivalInfo.arrival_by == 'By Bus'" for="flight_vehicle_no">Vehicle No.:</label>
                <label v-if="arrivalInfo.arrival_by == 'By Boat'" for="flight_vehicle_no">Boat name:</label>
                <label v-if="arrivalInfo.arrival_by == 'By Cruise'" for="flight_vehicle_no">Cruise name:</label>
                <InputText id="flight_vehicle_no" v-model="arrivalInfo.flight_vehicle_no" type="text" />
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-4">
                <label for="arrival_time">Arrival time:</label>
                <InputText id="arrival_time" v-model="arrivalInfo.arrival_time" type="text" />
            </div>
            <div class="p-col-4">
                <label for="arrival_from_to">From-To:</label>
                <InputText id="arrival_from_to" v-model="arrivalInfo.arrival_from_to" type="text" />
            </div>
        </div>

        <Divider align="left">
            <div class="p-d-inline-flex p-ai-center" style="background-color: none">
                <span style="font-weight: 500">Departure Information</span>
            </div>
        </Divider>
        <div class="p-grid">
            <div class="p-col-4">
                <label for="depart_date">Departure date:</label>
                <Calendar id="depart_date" v-model="departInfo.depart_date" dateFormat="dd-M-yy" style="height: 2.15rem;" :class="{'p-invalid':v_departinfo$.depart_date.$error}" />
            </div>
            <div class="p-col-4">
                <label for="flight_vehicle_no">Departure by:</label>
                <Dropdown v-model="selectedDepartBy" @change="onDepartByChange()" :options="arr_depart_by" optionLabel="by" :class="{'p-invalid':v_departinfo$.depart_by.$error}" />
            </div>
            <div class="p-col-4">                
                <label v-if="departInfo.depart_by == '' || departInfo.depart_by == 'By Flight'" for="flight_vehicle_no">Flight No.:</label>
                <label v-if="departInfo.depart_by == 'By Bus'" for="flight_vehicle_no">Vehicle No.:</label>
                <label v-if="departInfo.depart_by == 'By Boat'" for="flight_vehicle_no">Boat name:</label>
                <label v-if="departInfo.depart_by == 'By Cruise'" for="flight_vehicle_no">Cruise name:</label>
                <InputText id="flight_vehicle_no" v-model="departInfo.flight_vehicle_no" type="text" />
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-4">
                <label for="depart_time">Departure time:</label>
                <InputText id="depart_time" v-model="departInfo.depart_time" type="text" />
            </div>
            <div class="p-col-4">
                <label for="depart_from_to">From-To:</label>
                <InputText id="depart_from_to" v-model="departInfo.depart_from_to" type="text" />
            </div>
        </div>

        <Divider align="left">
            <div class="p-d-inline-flex p-ai-center" style="background-color: none">
                <span style="font-weight: 500">Landing area</span>
            </div>
        </Divider>
        <div class="p-grid" v-for="(item, index) of landingArea" :key="index">
            <div class="p-col-4">
                <label v-if="index == 0">Area:</label>
                <!-- <InputText id="area" v-model="item.area" type="text" :class="{'p-invalid': v_landingareainfo$.$each.$response.$errors[index].area.length == 1 && landingarea_error}" /> -->
                <InputText id="area" v-model="item.area" type="text" />
            </div>
            <div class="p-col-4">
                <label v-if="index == 0">From date:</label>
                <Calendar id="from_date" v-model="item.from" dateFormat="dd-M-yy" style="height: 2.15rem;" />
            </div>
            <div class="p-col-4">
                <label v-if="index == 0">To date:</label>
                <Calendar id="to_date" v-model="item.to" dateFormat="dd-M-yy" style="height: 2.15rem;" />
            </div>
        </div>
    </div>
</form>
<!-- <div v-if="bookingInfo.id != '' && !showLoadingScreen" class="form-view p-fluid">
    <Divider align="left">
        <div class="p-d-inline-flex p-ai-center" style="background-color: none">
            <span style="font-weight: 500">Hotel Booking</span>
        </div>
    </Divider>
    <BookingHotelForm v-if="bookingInfo.id !== ''" :BookingID="parseInt(bookingInfo.id)" />

    <Divider align="left">
        <div class="p-d-inline-flex p-ai-center" style="background-color: none">
            <span style="font-weight: 500">Restaurant Booking</span>
        </div>
    </Divider>
</div> -->
<ConfirmDialog></ConfirmDialog>
</template>

<script>
import { ref, reactive, computed, onMounted } from 'vue';
import { useToast } from "primevue/usetoast";
import { useRoute } from 'vue-router';
import { useAbility } from '@casl/vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import dayjs from 'dayjs';
import BookingCompany from '../components/Booking_Company.vue';
import LoadingBookingData from '../views/LoadingBookingData.vue';
import ItineraryService from '../service/ItineraryService';
import BookingService from '../service/BookingService';

export default {
    setup() {
        const { can } = useAbility();
        const toast = useToast();
        const route = useRoute();
        let form_type = ref('add')
        const isBookingCompanyDialogShow = ref(false)
        const showLoadingScreen = ref(false)
                
        const itinService = ref(new ItineraryService())
        const bookingService = ref(new BookingService())

        onMounted(() => {
            getBookingCompany()
            getItinerary()
            initBookingInfo()

            if(route.name == 'updatebooking') {
                showLoadingScreen.value = true
                bookingInfo.id = route.params.id

                getBookingById(bookingInfo.id)
            }
        })

        const getBookingById = (bookingID) => {
            bookingService.value.findBookingById(bookingID).then(data => {
                if(!data.errorResponse) {
                    Object.assign(bookingInfo, data.bookingInfo[0])
                    selectedBookingCompany.value = bookingCompanyList.value.find(item => item.id == bookingInfo.bookingcompany_id)
                    selectedBookingType.value = bookingType.value.find(item => item.booking_type == bookingInfo.booking_type)
                    selectedItinerary.value = itineraryList.value.find(item => item.id == bookingInfo.itinerary_id)
                    bookingStatus.value = bookingInfo.booking_status

                    Object.assign(arrivalInfo, data.arrivalInfo[0])
                    arrivalInfo.arrival_date = new Date(data.arrivalInfo[0].arrival_date)
                    selectedArrivalBy.value = arr_depart_by.value.find(arrival => arrival.by == arrivalInfo.arrival_by)

                    Object.assign(departInfo, data.departInfo[0])
                    departInfo.depart_date = new Date(data.departInfo[0].depart_date)
                    selectedDepartBy.value = arr_depart_by.value.find(depart => depart.by == departInfo.depart_by)
                    
                    landingAreaExistingData.value = data.landingAreaInfo.map(area => {
                        return {
                            id: area.id,
                            area: area.area,
                            from: new Date(area.from),
                            to: new Date(area.to),
                            booking_id: area.booking_id
                        }
                    })
                    getItinLandingArea()
                    showLoadingScreen.value = false
                } else {
                    toast.add({severity:'warn', summary: 'Error ' + data.status, detail: 'Fetching booking data failed.', life: 5000});
                }
            })
        }

        //==============BOOKING INFORMATION==============
        let bookingInfo = reactive({})
        let bookingType = ref([{booking_type: 'FIT'}, {booking_type: 'GIT'}, {booking_type: 'MICE'}])
        let selectedBookingType = ref()
        const bookingStatus = ref()
        const tour_code_input = ref()

        const initBookingInfo = () => {
            const bookingInfoForm = {
                id: '',
                tour_code: '',
                pax: null,
                nationality: '',
                company: '',
                booking_type: '',
                bookingcompany_id: '',
                itinerary_id: null,
                booking_status: '',
            }
            Object.assign(bookingInfo, bookingInfoForm)

            // landingArea.value.push({area: '', from: '', to: ''})
        }

        const onBookingTypeChange = () => {
            bookingInfo.booking_type = selectedBookingType.value.booking_type
        }

        const onBookingStatusChange = () => {
            bookingInfo.booking_status = bookingStatus.value
        }
        
        //==============BOOKING COMPANY==============
        let bookingCompanyList = ref([])
        let selectedBookingCompany = ref()

        const showBookingCompanyDialog = () => {
            isBookingCompanyDialogShow.value = !isBookingCompanyDialogShow.value
        }

        const getBookingCompany = () => {
            bookingService.value.getBookingCompany().then((data) => {
                bookingCompanyList.value = data
            })
        }

        const onBookingCompanyChange = () => {
            bookingInfo.bookingcompany_id = selectedBookingCompany.value.id
        }

        //==============ITINERARY==============
        let itineraryList = ref([])
        let selectedItinerary = ref()
        let itinLandingArea = ref([])

        const getItinerary = () => {
            itinService.value.getItinerary().then((data) => {
                itineraryList.value = data;
            });
        }

        const getItinLandingArea = () => {
            if(selectedItinerary.value){
                itinService.value.getLandingArea(selectedItinerary.value.id).then(data => {
                    itinLandingArea.value = data
                    listLandingArea()
                })
                bookingInfo.itinerary_id = selectedItinerary.value.id
            }
        }


        //==============ARRIVAL DEPARTURE==============
        let arr_depart_by = ref([
            {by: 'By Bus'},
            {by: 'By Flight'},
            {by: 'By Boat'},
            {by: 'By Cruise'},
        ])
        let arrivalInfo = reactive({
            arrival_date: '',
            arrival_by: '',
            flight_vehicle_no: '',
            arrival_time: '',
            arrival_from_to: '',
            booking_id: '',
        })
        let selectedArrivalBy = ref();
        let departInfo = reactive({
            depart_date: '',
            depart_by: '',
            flight_vehicle_no: '',
            depart_time: '',
            depart_from_to: '',
            booking_id: '',
        })
        let selectedDepartBy = ref();

        const onArrivalByChange = () => {
            arrivalInfo.arrival_by = selectedArrivalBy.value.by
        }

        const onDepartByChange = () => {
            departInfo.depart_by = selectedDepartBy.value.by
        }

        //==============LANDING AREA==============
        let landingArea = ref([])
        let landingAreaExistingData = ref([])

        const listLandingArea = () => {
            if(itinLandingArea.value.length > 0 && arrivalInfo.arrival_date != '') {
                landingArea.value.length = 0
                const arrivalDate = new Date(arrivalInfo.arrival_date)
                itinLandingArea.value.forEach((item, index) => {
                    if(index == 0) {
                        landingArea.value.push({
                            id: (typeof landingAreaExistingData.value[index] === 'undefined') ? '' : landingAreaExistingData.value[index].id,
                            area: item.landing_area,
                            from: arrivalDate,
                            to: new Date(dayjs(arrivalDate).add((item.day - 1), 'day')),
                            booking_id: (typeof landingAreaExistingData.value[index] === 'undefined') ? '' : landingAreaExistingData.value[index].booking_id,
                        })
                    } else {
                        const last_Date = new Date(landingArea.value[index - 1].to)
                        landingArea.value.push({
                            id: (typeof landingAreaExistingData.value[index] === 'undefined') ? '' : landingAreaExistingData.value[index].id,
                            area: item.landing_area,
                            from: last_Date,
                            to: new Date(dayjs(last_Date).add((item.day - 1), 'day')),
                            booking_id: (typeof landingAreaExistingData.value[index] === 'undefined') ? '' : landingAreaExistingData.value[index].booking_id,
                        })
                    }
                })
                departInfo.depart_date = new Date(landingArea.value[landingArea.value.length - 1].to)
            }
        }

        //==============FORM VALIDATIONS==============
        const bookingInfoRules = computed(() => {
            return {
                tour_code: { required },
                pax: { required },
                nationality: { required },
                bookingcompany_id: { required },
                booking_type: { required },
                booking_status: { required },
            }
        })
        const arrivalInfoRules = computed(() => {
            return {
                arrival_date: { required },
                arrival_by: { required },
            }
        })
        const departInfoRules = computed(() => {
            return {
                depart_date: { required },
                depart_by: { required },
            }            
        })
        const landingAreaRules = computed(() => {
            return {
                $each: helpers.forEach({
                    area: { required },
                    from: { required },
                    to: { required },
                })
            }
        })
        const v_bookinginfo$ = useVuelidate(bookingInfoRules, bookingInfo)
        const v_arrivalinfo$ = useVuelidate(arrivalInfoRules, arrivalInfo)
        const v_departinfo$ = useVuelidate(departInfoRules, departInfo)
        const v_landingareainfo$ = useVuelidate(landingAreaRules, landingArea)
        let landingarea_error = ref(false)

        const validateBookingForm = () => {
            v_bookinginfo$.value.$validate();
            v_arrivalinfo$.value.$validate();
            v_departinfo$.value.$validate();
            // const v_landingareainfo = validateLandingArea();
            // if(!v_arrivalinfo$.value.$error && !v_departinfo$.value.$error && !v_bookinginfo$.value.$error && !v_landingareainfo){
            if(!v_arrivalinfo$.value.$error && !v_departinfo$.value.$error && !v_bookinginfo$.value.$error){
                return true
            } else {
                toast.add({severity:'warn', summary: 'Warning', detail:'Value is required.', life: 3000});
                return false
            }
        }

        // const validateLandingArea = () => {
        //     for(let error of v_landingareainfo$.value.$each.$response.$errors) {
        //         if(error.area.length > 0 || error.from.length > 0 || error.to.length > 0) {
        //             landingarea_error.value = true
        //             return true
        //         }
        //     }
        //     landingarea_error.value = false
        //     return false
        // }

        const addBooking = () => {
            if(validateBookingForm()) {
                bookingService.value.addBooking(bookingInfo, arrivalInfo, departInfo, landingArea.value).then(data => {
                    if(!data.errorResponse) {
                        v_bookinginfo$.value.$reset();
                        v_arrivalinfo$.value.$reset();
                        v_departinfo$.value.$reset();
                        landingarea_error.value = false

                        clearForm()
                        tour_code_input.value.$el.focus()
                        toast.add({severity:'success', summary: 'Success', detail:'Booking has been created.', life: 3000});
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    }
                })
            }
        }

        const updateBooking = () => {
            if(validateBookingForm()) {
                bookingService.value.updateBooking(route.params.id, bookingInfo, arrivalInfo, departInfo, landingArea.value).then(data => {
                    if(!data.errorResponse) {
                        v_bookinginfo$.value.$reset();
                        v_arrivalinfo$.value.$reset();
                        v_departinfo$.value.$reset();
                        landingarea_error.value = false

                        toast.add({severity:'success', summary: 'Info', detail:'Booking has been updated.', life: 3000});        
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    }
                })
            }
        }

        const clearForm  = () => {
            landingArea.value.length = 0
            initBookingInfo()
            bookingStatus.value = ''
            selectedBookingCompany.value = ''
            selectedBookingType.value = ''
            selectedItinerary.value = ''
            selectedArrivalBy.value = ''
            selectedDepartBy.value = ''

            let clearArrivalInfo = {
                arrival_date: '',
                arrival_by: '',
                flight_vehicle_no: '',
                arrival_time: '',
                arrival_from_to: '',
            }
            Object.assign(arrivalInfo, clearArrivalInfo)

            let clearDepartInfo = reactive({
                depart_date: '',
                depart_by: '',
                flight_vehicle_no: '',
                depart_time: '',
                depart_from_to: '',
            })
            Object.assign(departInfo, clearDepartInfo)
        }

        return {
            can,
            showLoadingScreen,
            showBookingCompanyDialog,
            isBookingCompanyDialogShow,
            getBookingCompany,
            bookingCompanyList,
            selectedBookingCompany,
            onBookingCompanyChange,
            
            form_type,
            tour_code_input,
            v_bookinginfo$,
            onBookingStatusChange,
            bookingInfo,
            addBooking,
            updateBooking,

            itineraryList,
            selectedItinerary,
            getItinLandingArea,

            bookingType,
            selectedBookingType,
            onBookingTypeChange,
            bookingStatus,
            
            arr_depart_by,
            v_arrivalinfo$,
            arrivalInfo,
            onArrivalByChange,
            selectedArrivalBy,
            v_departinfo$,
            departInfo,
            onDepartByChange,
            selectedDepartBy,

            v_landingareainfo$,
            landingarea_error,
            landingArea,
            listLandingArea,
        }
    },
    components: {
        BookingCompany,
        LoadingBookingData,
    }
}
</script>

<style lang="scss" scoped>
.form-view {
    padding: 0;

    label {
        width: auto;
    }
}

.p-col-6 {
    padding: .3rem .5rem;
}

.p-field-radiobutton {
    margin-bottom: 0;
}

.p-field-radiobutton label:hover {
    cursor: pointer;
}

.p-divider {
    margin: 0; 
    margin: .5rem 0;
}
</style>